import React, { useEffect, useState } from "react";
import PrimaryButton from "./PrimaryButton";
import { useCreateResourceMutation } from "../features/api/apiSlice";
import toast from "react-hot-toast";
import { initHapticFeedback, initCloudStorage } from "@telegram-apps/sdk";
import { motion } from "framer-motion";

const DailyReward = ({ data, onRewardClaimed, rewardStreak }) => {
  const hapticFeedback = initHapticFeedback();
  const cloudStorage = initCloudStorage();

  // State variables
  const [userData, setUserData] = useState(null);
  const [telegramId, setTelegramId] = useState(null);
  const [storedEndTime, setStoredEndTime] = useState(null);
  // const [rewardStreak, setRewardStreak] = useState(0);
  const [countdown, setCountdown] = useState(null);
  const [formData, setFormData] = useState({ telegramId: null });

  // Fetch userData and storedEndTime from cloudStorage
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDataString = await cloudStorage.get("userData");
        if (userDataString) {
          const data = JSON.parse(userDataString);
          setUserData(data);
          setTelegramId(data.telegramId);

          // Fetch storedEndTime
          const storedEndTimeString = await cloudStorage.get(
            `countdownEndTime_${data.telegramId}`
          );
          if (storedEndTimeString) {
            setStoredEndTime(storedEndTimeString);
          }
        }
      } catch (error) {
        console.error("Error fetching data from cloudStorage:", error);
      }
    };

    fetchData();
  }, [onRewardClaimed]); // Add onRewardClaimed as a dependency

  // Update formData when telegramId changes
  useEffect(() => {
    if (telegramId) {
      setFormData({ telegramId });
    }
  }, [telegramId]);

  // Countdown logic
  useEffect(() => {
    if (storedEndTime && telegramId) {
      const endTime = new Date(storedEndTime);

      const interval = setInterval(() => {
        const newCountdown = calculateRemainingTime(endTime);
        if (newCountdown === null) {
          clearInterval(interval);
          setCountdown(null);
          cloudStorage.delete(`countdownEndTime_${telegramId}`);
          setStoredEndTime(null);
        } else {
          setCountdown(newCountdown);
        }
      }, 1000);

      return () => clearInterval(interval); // Clean up interval on component unmount
    }
  }, [storedEndTime, telegramId]);

  // Function to calculate remaining time for countdown
  const calculateRemainingTime = (endTime) => {
    const now = new Date();
    const remainingTime = endTime - now;
    if (remainingTime <= 0) {
      return null; // Countdown finished
    }

    const hours = Math.floor(remainingTime / (1000 * 60 * 60));
    const minutes = Math.floor(
      (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const [createResource, { isLoading }] = useCreateResourceMutation();

  const handleCollect = async () => {
    hapticFeedback.impactOccurred("soft");
    try {
      const res = await createResource({
        url: `/user/updateDailyReward`,
        formData: { ...formData },
      }).unwrap();

      if (res.status === "success") {
        hapticFeedback.notificationOccurred("success");

        // Update userData in cloudStorage
        const updatedUserData = {
          ...userData,
          rewardStreak: res.data?.streak,
          coins: res.data?.coins,
        };
        await localStorage.setItem("userData", JSON.stringify(updatedUserData));

        // Set new countdown end time for 24 hours from now
        const countdownEndTime = new Date(
          new Date().getTime() + 24 * 60 * 60 * 1000
        );
        await cloudStorage.set(
          `countdownEndTime_${telegramId}`,
          countdownEndTime.toString()
        );
        setStoredEndTime(countdownEndTime.toString());

        // Notify the Home component
        if (onRewardClaimed) {
          onRewardClaimed();
        }
      } else {
        hapticFeedback.notificationOccurred("warning");
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  // Handle loading state
  if (!userData || !telegramId) {
    return <div>Loading...</div>;
  }

  return (
    <div className="grid grid-cols-4 w-full">
      {data.map((item, idx) =>
        rewardStreak > idx ? (
          <div className="flex items-center pt-3 rounded-md" key={idx}>
            <div className="relative bg-gradient-to-b from-[#0094FF]/20 to-[#00B2FF]/40 w-[100px] h-[80px] mx-1 rounded-md">
              <div className="absolute w-full h-full inset-0 bg-black bg-opacity-25 backdrop-blur-sm rounded-md">
                <CheckmarkIconWithGlow />
              </div>

              <div className="flex flex-col items-center gap-4 py-2">
                <h1 className="text-center">{item.title}</h1>
                <p className="flex items-center gap-1">
                  {item.coin}
                  <span>
                    <img src={item.icon} alt="icon" />
                  </span>
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex items-center pt-3 rounded-md" key={idx}>
            <div
              className={`bg-gradient-to-b from-[#0094FF]/20 to-[#00B2FF]/40 w-[100px] h-[80px] mx-1 rounded-md ${
                rewardStreak === idx ? "border border-[#AA17DC]" : ""
              }`}
            >
              <div className="flex flex-col items-center gap-4 py-2">
                <h1 className="text-center">{item.title}</h1>
                <p className="flex items-center gap-1">
                  {item.coin}
                  {/* SVG or icon */}
                </p>
              </div>

              {/* Show the Claim button only for the current day's reward */}
              {rewardStreak === idx && (
                <div className="fixed bottom-28 right-0 left-0 px-5">
                  <PrimaryButton
                    type={"button"}
                    text={"Claim"}
                    className={`p-4 ${
                      storedEndTime
                        ? "bg-gray-500 text-white"
                        : "bg-gradient-to-r from-[#4952E5] to-[#CC5DCF]"
                    }`}
                    onClick={handleCollect}
                    isLoading={isLoading}
                    disabled={!!storedEndTime}
                  />
                </div>
              )}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default DailyReward;

// ... (CheckmarkIconWithGlow component remains the same)

export const CheckmarkIconWithGlow = () => {
  const checkmarkVariants = {
    hidden: { opacity: 0, pathLength: 0 },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
  };

  const circleVariants = {
    hidden: { opacity: 0, pathLength: 0 },
    visible: {
      opacity: 1,
      pathLength: 0.8,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  return (
    <div className="flex items-center justify-center w-full h-full ">
      <motion.div
        className="relative flex items-center justify-center w-full h-full"
        initial="hidden"
        animate="visible"
      >
        {/* Glowing Circle */}
        <motion.div
          className="absolute w-14 h-14 rounded-full bg-yellow-400 blur-xl"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, ease: "easeInOut" }}
        ></motion.div>

        {/* Circle */}
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
          className="w-10 h-10 stroke-yellow-500"
          initial="hidden"
          animate="visible"
        >
          <motion.circle
            cx="26"
            cy="26"
            r="24"
            stroke="yellow"
            strokeWidth="4"
            fill="none"
            variants={circleVariants}
          />
          {/* Checkmark Path */}
          <motion.path
            d="M5 27L20 42L47 12"
            stroke="yellow"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
            variants={checkmarkVariants}
          />
        </motion.svg>
      </motion.div>
    </div>
  );
};
