import React, { useRef } from "react";
import PageTitle from "../components/PageTitle";
import Lists from "../components/Lists";
import GoldBox from "../components/GoldBox";
import Gift from "../assets/icons/treasure.svg";
import ConnectButton from "../components/ConnectButton";
import toast from "react-hot-toast";
import { initUtils, initHapticFeedback } from "@telegram-apps/sdk";
import { InviteFriendData } from "../constants/data";
import Marquee from "react-fast-marquee"; // Import react-fast-marquee
import { WarningIcon } from "../constants/icons";
import MarqueeItems from "../components/MarqueeItems";

const Invite = () => {
  console.log("ded :", InviteFriendData);
  const hapticFeedback = initHapticFeedback();
  const utils = initUtils();

  const userData = JSON.parse(localStorage.getItem("userData"));
  const referralCode = userData.referralCode;
  const referredFriends = userData.referredFriends;

  console.log("referredFriends :", referredFriends);
  const telegramLink = `http://t.me/MegsKombatBot/MegsKombat?startapp=${referralCode}`;

  const handleCopyClick = () => {
    hapticFeedback.notificationOccurred("success");
    toast.dismiss();

    navigator.clipboard.writeText(telegramLink).then(
      () => {
        toast.success("Link copied to clipboard!", {
          icon: null,
          style: {
            backgroundColor: "whitesmoke",
            color: "black",
            padding: "0.75rem",
            borderRadius: "9999px",
            boxShadow: "0 10px 15px rgba(0, 0, 0, 0.1)",
          },
        });
      },
      (err) => {
        console.error("Failed to copy: ", err);
      }
    );
  };
  const shareOnTelegram = () => {
    hapticFeedback.impactOccurred("soft");
    if (telegramLink) {
      utils.shareURL(
        telegramLink,
        "💸 Easy Peasy! 💸 Open the app, connect YouTube, and turn into a millionaire! 🚀💰 Join us, and extend your word"
      );
    }
  };

  const getFirstLetter = (userData) => {
    if (userData.username) {
      return userData.username.charAt(0).toUpperCase();
    } else if (userData.firstName || userData.lastName) {
      return `${userData.firstName?.charAt(0) || ""}${
        userData.lastName?.charAt(0) || ""
      }`.toUpperCase();
    }
    return "U"; // Fallback if no username, first name, or last name exists
  };

  const marqueRef = useRef(null);
  return (
    <div className="w-full">
      <div>
        <PageTitle
          title={"Invite Friends!"}
          subtitle={"Share your earnings with your friends"}
        />
      </div>
      <div className="pt-3 mx-5 my-8">
        <GoldBox
          icon={Gift}
          title={"Invite a friend and maximize your earnings"}
          subtitle={"400 Megs Per Friend"}
          button={false}
          className={"grid grid-cols-3"}
          textClassName={" col-span-2 "}
        />
      </div>

      <div className="m-5">
        <div>
          <h1 className="font-aileronSemiBold text-white text-xl">
            Referral Milestones
          </h1>
          <p className="text-[#9792CF] text-start">
            When the referrer reaches specific milestones, they receive
            additional rewards.
          </p>
        </div>

        <div ref={marqueRef} className="py-6 -mx-5">
          <MarqueeItems items={InviteFriendData} />
        </div>

        <div className="flex items-center gap-4">
          <span>
            <WarningIcon />
          </span>
          <p className="text-[#9792CF] font-aileronSemiBold">
            You will earn %10 of any invited friend once he/she connects his
            youtube
          </p>
        </div>
      </div>

      {/* List */}
      <div className="pb-28">
        <h1 className="font-aileronSemiBold px-5 text-white text-xl">
          List of friends
        </h1>
        {referredFriends.length === 0 ? (
          <h1 className="text-[#9792CF] font-aileronSemiBold text-center py-8">
            You haven't invited anyone yet
          </h1>
        ) : (
          referredFriends.map((item, idx) => (
            <Lists
              key={idx}
              userData={item}
              title={
                item.username
                  ? item.username
                  : `${item.firstName} ${item.lastName}`
              }
              isSubtitle={false}
              data={Math.floor(item.coinRewardByReferedMe)}
              getFirstLetter={getFirstLetter}
              icon={true}
            />
          ))
        )}
      </div>

      <div className="mt-3">
        <ConnectButton copyClick={handleCopyClick} onClick={shareOnTelegram} />
      </div>
    </div>
  );
};

export default Invite;
