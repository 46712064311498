import React from "react";
import PrimaryButton from "../components/PrimaryButton";
const NotFound = () => {
  const handleOpenTelegram = () => {
    window.location.href = "http://t.me/MegsKombatBot/MegsKombat?startapp";
  };
  return (
    <div className="w-full bg-custom-gradient min-h-screen fixed inset-0 flex flex-col justify-between">
      <div className="flex-grow flex flex-col gap-4 justify-center items-center">
        <h1 className="text-8xl font-bold text-[#9792CF]">404</h1>
        <p className="text-[#9792CF] font-aileronRegular text-xl">
          Page not found
        </p>
      </div>
      <div className="mb-10 mx-10 flex justify-center">
        <PrimaryButton
          type={"button"}
          text={"Open in Telegram"}
          className={
            " bg-gradient-to-r from-[#4952E5] to-[#CC5DCF]  p-4 text-xl font-aileronSemiBold"
          }
          boxShadow={"0 4px 20px rgba(245, 14, 250, 0.6)"}
          onClick={handleOpenTelegram}
        />
      </div>
    </div>
  );
};

export default NotFound;
