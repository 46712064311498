import React from "react";
import PrimaryButton from "./PrimaryButton";
import { CopyIcon } from "../constants/icons";

const ConnectButton = ({ onClick, copyClick }) => {
  return (
    <div className="w-full fixed bottom-24 right-0 left-0 px-4 z-10">
      <div className="flex justify-center items-center gap-3">
        <PrimaryButton
          type={"button"}
          text={"Invite a friend"}
          className={" bg-gradient-to-r p-4 from-[#4952E5] to-[#CC5DCF] "}
          boxShadow={"0 4px 20px rgba(245, 14, 250, 0.6)"}
          onClick={onClick}
        />
        <div
          onClick={copyClick}
          className="bg-[#8F8DCA]/40 border border-[#4952E5] rounded-lg w-[54px] h-[54px] flex justify-center items-center"
        >
          <CopyIcon />
        </div>
      </div>
    </div>
  );
};

export default ConnectButton;
