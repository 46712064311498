import React from "react";
import LoadingIcon from "./LoadingIcon";

const PrimaryButton = ({
  text,
  onClick,
  type,
  className,
  boxShadow,
  isLoading,
  icon,
  coins,
  disabled,
  isTimerCheck,
}) => {
  return (
    <div className="w-full flex flex-col gap-5 justify-center">
      <button
        disabled={disabled}
        className={`text-white flex justify-center items-center  w-full rounded-xl ${className} font-semibold text-xl`}
        type={type}
        onClick={onClick}
        style={{ boxShadow: boxShadow }}
      >
        {isLoading ? (
          <div className="flex justify-center items-center w-full">
            <LoadingIcon />
          </div>
        ) : (
          text
        )}
        {coins && <span className="px-3">{Math.floor(coins)}</span>}
        {icon && <span>{icon}</span>}
      </button>
      {isTimerCheck > 0 && (
        <h1 className="font-aileronRegular leading-relaxed text-center tracking-wide text-[#9792CF] text-lg">
          Wait 1 hour for moderation check to claim the prize
        </h1>
      )}
    </div>
  );
};

export default PrimaryButton;
