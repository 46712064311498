import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingSplash from "../../pages/Splashs/LoadingSplash";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import {
  loginFailure,
  loginStart,
  loginSuccess,
  selectCurrentUser,
  selectError,
  selectIsLoading,
} from "./authSlice";
import { useCreateResourceMutation } from "../api/apiSlice";
import { initInitData, retrieveLaunchParams } from "@telegram-apps/sdk";

import { initCloudStorage } from "@telegram-apps/sdk";

const RequireAuth = () => {
  const cloudStorage = initCloudStorage();
  const location = useLocation();
  const dispatch = useDispatch();
  const [createResource] = useCreateResourceMutation();
  const [isCollectClicked, setIsCollecClicked] = useState(false);
  const [isWelcomeCoinCollected, setIsWelcomeCoinCollected] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [authLoading, setAuthLoading] = useState(true); // Local loading state

  useEffect(() => {
    const expandApp = () => {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.expand();
      } else {
        console.warn("Telegram WebApp is not available");
      }
    };

    expandApp();
  }, []);
  useEffect(() => {
    const authenticateUser = async () => {
      dispatch(loginStart());

      try {
        const { initDataRaw, startParam } = await retrieveLaunchParams();
        const initData = initInitData();
        localStorage.setItem("initData", JSON.stringify(initData));
        localStorage.setItem("telegramId", JSON.stringify(initData));

        const requestBody = {
          initDataRaw: initDataRaw,
        };
        const { data: responseData, error: fetchError } = await createResource({
          url: `/auth`,
          formData: requestBody,
          params: startParam ? { referralCode: startParam } : {},
        });
        if (fetchError) {
          console.error("Fetch error:", fetchError);
          dispatch(loginFailure("Authentication failed"));
          setAuthLoading(false); // Set loading to false on error
          return;
        }

        if (
          responseData.status === "success" &&
          responseData.data?.existingUser
        ) {
          const user = responseData.data.existingUser;
          localStorage.setItem("userData", JSON.stringify(user));
          await cloudStorage.set("userData", JSON.stringify(user));

          dispatch(loginSuccess(user));
        } else {
          dispatch(loginFailure("Authentication failed"));
        }
      } catch (err) {
        console.log("error => :", err);
        dispatch(loginFailure(err.message));
      } finally {
        setAuthLoading(false); // Always set loading to false when done
      }
    };

    authenticateUser();
  }, [createResource, dispatch]);

  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);
  const user = useSelector(selectCurrentUser);

  // Handle user state updates
  useEffect(() => {
    if (user && user.youtube) {
      setIsCollecClicked(user.youtube.isCollectClicked);
      setIsConnected(user.youtube.isConnected);
    }
    if (user) {
      setIsWelcomeCoinCollected(user.isWelcomeCoinCollected);
    }
  }, [user]);

  // Return loading splash if still loading
  if (authLoading || isLoading) {
    return <LoadingSplash />;
  }
  // Return error page if there's an error
  if (error) {
    return <Navigate to="/Error" state={{ from: location }} replace />;
  }

  if (!isWelcomeCoinCollected) {
    return <Navigate to="/Welcome" state={{ from: location }} replace />;
  }
  if (isWelcomeCoinCollected) {
    return <Outlet />;
  }
};

export default RequireAuth;
