import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { SharkLogoIcon, UserIcon } from "../constants/icons";
import { initHapticFeedback } from "@telegram-apps/sdk";
import DropDown from "../components/DropDown"; // Import your DropDown component

const TopBar = () => {
  const hapticFeedback = initHapticFeedback();
  const location = useLocation();
  const path = location.pathname;
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [openProfile, setOpenProfile] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false); // State to control dropdown visibility

  const toggleDropdown = (e) => {
    hapticFeedback.impactOccurred("soft");
    e.preventDefault();
    setIsDropDownOpen(!isDropDownOpen); // Toggle dropdown visibility
  };

  return (
    <div
      className={`fixed top-0 left-0 right-0  ${
        path === "/Games" ? "bg-transparent" : "bg-[#1E2160]/55"
      }  h-16 text-white z-50`}
    >
      <div className="flex justify-between items-center w-full p-4">
        <div>
          <Link
            to={"/"}
            className="text-2xl flex gap-3 items-center font-aileronSemiBold"
          >
            <SharkLogoIcon fill="#fff" width="40px" height="40px" />
          </Link>
        </div>
        <div
          onClick={toggleDropdown}
          className="flex items-center justify-center" // Ensure the parent has relative position
        >
          <span className="pr-2 text-lg font-aileronSemiBold">
            {userData && userData?.firstName}
          </span>
          <div className="bg-gradient-to-b from-[#AA17DC] to-[#179DE8] rounded-full p-px">
            <div className="bg-[#1E2160] rounded-full">
              <UserIcon />
            </div>
          </div>
        </div>
      </div>

      {/* Render the DropDown component */}
      {isDropDownOpen && (
        <div className="flex justify-end items-end mr-3">
          <DropDown
            isOpen={isDropDownOpen}
            setIsDropDownOpen={setIsDropDownOpen}
          />
        </div>
      )}
    </div>
  );
};

export default TopBar;
