import React, { useEffect } from "react";
import PrimaryButton from "./PrimaryButton";
import { useLazyGetResourceQuery } from "../features/api/apiSlice";
import { initUtils } from "@telegram-apps/sdk";

const ConnectYoutube = () => {
  const utils = initUtils();
  //Local storage
  const userData = JSON.parse(localStorage.getItem("userData"));
  const telegramId = userData.telegramId;
  //API
  const [getResource, { data: response, error, isLoading }] =
    useLazyGetResourceQuery();

  //Handler
  const handleLogin = async (e) => {
    e.preventDefault();
    if (!telegramId) {
      console.log("No telegramId found");
      return;
    }
    try {
      const result = await getResource({
        url: `/youtube/auth-url`,
        params: { telegramId },
      });
    } catch (err) {
      console.log("error :", err);
    }
  };
  // Side effect to open the link when the response is available
  useEffect(() => {
    if (response?.url) {
      utils.openLink(response.url, { tryBrowser: true });

      // Close the mini app
      window.Telegram.WebApp.close();
    }
  }, [response, utils]);
  return (
    <div className="mx-5 mt-14">
      <div className=" bg-[#8185D2]/10 text-white p-2 rounded-lg shadow-lg border border-[#179DE8] ">
        <h2 className="text-center text-lg font-semibold mb-4">
          Connect Your Youtube
        </h2>
        <p className="text-center text-sm mb-6 ">
          Connect your youtube, earn coins based on your account age, number of
          views and subscribers
        </p>
        <PrimaryButton
          type={"button"}
          text={"Connect"}
          className={" bg-gradient-to-r from-[#4952E5] to-[#CC5DCF]   p-4"}
          boxShadow={"0 4px 20px rgba(245, 14, 250, 0.6)"}
          onClick={handleLogin}
        />
      </div>
    </div>
  );
};

export default ConnectYoutube;
