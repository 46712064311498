import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import AppRoutes from "./routes/appRoutes";
import VerifyYoutubeData from "./pages/VerifyYoutubeData";
import { SDKProvider } from "@telegram-apps/sdk-react";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <Router>
      <SDKProvider>
        <div className="App">
          <Routes>
            <Route path="*" element={<ConditionalRender />} />
          </Routes>
        </div>
      </SDKProvider>
    </Router>
  );
}

function ConditionalRender() {
  const location = useLocation();
  const queryParams = location.pathname;
  const hasVerifyYoutubeDataParam =
    queryParams === "/verifyYoutubeDataFromBrowser";

  return hasVerifyYoutubeDataParam ? (
    <VerifyYoutubeData />
  ) : (
    <>
      <Toaster />
      <AppRoutes />
    </>
  );
}

export default App;
