import React, { useEffect, useState } from "react";
import { useLazyGetResourceQuery } from "../features/api/apiSlice";
import LoadingIcon from "../components/LoadingIcon";
import PrimaryButton from "../components/PrimaryButton";

const VerifyYoutubeData = () => {
  // states
  const [isError, setIsError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [alreadyConnected, setIsAlreadyConnect] = useState(false);

  // API
  const [getResource, { data: response, error, isLoading }] =
    useLazyGetResourceQuery();

  useEffect(() => {
    if (window.location.pathname === "/verifyYoutubeDataFromBrowser") {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      const telegramId = urlParams.get("state");

      if (code && telegramId) {
        handleCallback(code, telegramId);
      }
    }
  }, []);

  const handleCallback = async (code, telegramId) => {
    try {
      await getResource({
        url: `/youtube/auth/callback`,
        params: { code, telegramId },
      });
      setIsAuthenticated(true);
      fetchChannelData(telegramId);
    } catch (err) {
      setIsError("Authentication failed");
    }
  };

  const fetchChannelData = async (telegramId) => {
    try {
      const result = await getResource({
        url: `/youtube/youtube-channel`,
        params: { telegramId },
      });

      if (result?.data?.data) {
        window.location.replace(
          "http://t.me/MegsKombatBot/MegsKombat?startapp"
        );
      } else if (result.error) {
        setIsError(result.error?.data?.message);
        setIsAlreadyConnect(true);
      }
    } catch (err) {
      console.log("Error fetching channel data:", err);
    }
  };

  const handleOpenTelegram = () => {
    window.location.replace("http://t.me/MegsKombatBot/MegsKombat?startapp");
  };

  return (
    <div className="w-full bg-custom-gradient min-h-screen fixed inset-0 flex flex-col justify-between">
      {isLoading ? (
        <div className="flex-grow flex flex-col gap-4 justify-center items-center">
          <LoadingIcon />
        </div>
      ) : error ? (
        <div className="flex-grow flex flex-col gap-24 justify-center items-center px-5">
          <h1 className="text-4xl font-aileronSemiBold text-white">Sorry!</h1>
          <p className="text-xl font-aileronRegular text-[#9792CF] text-center">
            {error?.data?.message || "An error occurred. Please try again."}
          </p>
          <div className="mb-10 mx-10 flex justify-center">
            <PrimaryButton
              type="button"
              text="Open in Telegram"
              className="bg-gradient-to-r from-[#4952E5] to-[#CC5DCF] p-4 text-xl font-aileronSemiBold"
              boxShadow="0 4px 20px rgba(245, 14, 250, 0.6)"
              onClick={handleOpenTelegram}
            />
          </div>
        </div>
      ) : alreadyConnected ? (
        <div className="flex-grow flex flex-col gap-4 justify-center items-center">
          <h1 className="text-4xl font-aileronSemiBold text-white">Sorry!</h1>
          <p className="text-xl font-aileronRegular text-[#9792CF] text-center">
            {isError}
          </p>
          <div className="mb-10 mx-10 flex justify-center">
            <PrimaryButton
              type="button"
              text="Open in Telegram"
              className="bg-gradient-to-r from-[#4952E5] to-[#CC5DCF] p-4 text-xl font-aileronSemiBold"
              boxShadow="0 4px 20px rgba(245, 14, 250, 0.6)"
              onClick={handleOpenTelegram}
            />
          </div>
        </div>
      ) : (
        <div className="flex-grow flex flex-col gap-4 justify-center items-center">
          <LoadingIcon />
        </div>
      )}
    </div>
  );
};

export default VerifyYoutubeData;
