import React, { useEffect } from "react";
import { bottomLinks } from "../constants/data";
import { NavLink } from "react-router-dom";
import { initHapticFeedback } from "@telegram-apps/sdk";
const BottomBar = () => {
  const hapticFeedback = initHapticFeedback();
  const handleIconClick = () => {
    // Trigger haptic feedback when an icon is clicked
    hapticFeedback.selectionChanged();
  };
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-[#353E71] h-20 border border-[#179DE8] rounded-t-2xl z-50">
      {/*Links*/}
      <div className="flex justify-between items-center">
        {bottomLinks.map((link, idx) => (
          <NavLink
            to={link.link}
            key={idx}
            className={({ isActive }) =>
              `p-5 text-[#9792CF] transition duration-300 ease-in-out transform  ${
                isActive ? "text-white" : ""
              }`
            }
            onClick={handleIconClick}
          >
            {link.icon}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default BottomBar;
