import React, { useEffect, useState } from "react";
import SharkBg from "../../assets/images/holding youtube balls.jpg";
import PrimaryButton from "../../components/PrimaryButton";
import { useLazyGetResourceQuery } from "../../features/api/apiSlice";
import { initUtils } from "@telegram-apps/sdk";
const StartSplash = () => {
  const utils = initUtils();
  //Local storage
  const userData = JSON.parse(localStorage.getItem("userData"));
  const telegramId = userData.telegramId;
  //API
  const [getResource, { data: response, error, isLoading }] =
    useLazyGetResourceQuery();

  //Handler
  const handleLogin = async (e) => {
    e.preventDefault();
    if (!telegramId) {
      console.log("No telegramId found");
      return;
    }
    try {
      const result = await getResource({
        url: `/youtube/auth-url`,
        params: { telegramId },
      });
    } catch (err) {
      console.log("error :", err);
    }
  };
  // Side effect to open the link when the response is available
  useEffect(() => {
    if (response?.url) {
      utils.openLink(response.url, { tryBrowser: true });

      // Close the mini app
      window.Telegram.WebApp.close();
    }
  }, [response, utils]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  // Update screen width and height based on Telegram mini app container size
  useEffect(() => {
    const updateAppSize = () => {
      const appWidth =
        window.Telegram.WebApp.viewportWidth || window.innerWidth;
      const appHeight =
        window.Telegram.WebApp.viewportHeight || window.innerHeight;

      setScreenWidth(appWidth);
      setScreenHeight(appHeight);
    };

    // Initial call to set app size
    updateAppSize();

    // Listen for changes in mini app size (if the Telegram API triggers such updates)
    window.addEventListener("resize", updateAppSize);

    return () => {
      window.removeEventListener("resize", updateAppSize);
    };
  }, []);
  return (
    <div className="w-full bg-custom-gradient min-h-screen fixed inset-0">
      <div
        className="relative"
        style={{
          height: `${screenHeight * 0.7}px`,
          maxHeight: `${screenHeight * 0.7}px`,
        }} // Set height to 70% of screen height
      >
        <img src={SharkBg} alt="" className="w-full h-full object-cover" />
        <div className="absolute bg-gradient-to-b from-[#000000]/0 to-[#001539] bottom-0 right-0 left-0 z-10 h-32"></div>
        <div className="absolute bg-gradient-to-b from-[#000000]/0 to-[#001539] bottom-0 right-0 left-0 z-10 h-32"></div>
      </div>

      <div className="bg-gradient-to-b from-[#001539] to-[#001539] h-full">
        <div className="flex flex-col justify-center items-center gap-2 sm-phone:pb-10 iphone-12-pro:pb-20 iphone-11:pb-24">
          <h1 className="font-aileronItalic text-white text-3xl">
            Lets start the journey!
          </h1>
          <p className="text-[#9792CF] text-md">
            Connect your youtube to proceed
          </p>
        </div>

        <div className="fixed bottom-10 right-0 left-0 mx-5 z-50">
          <PrimaryButton
            type={"button"}
            text={"START"}
            className={" bg-gradient-to-r from-[#4952E5] to-[#CC5DCF]   p-4"}
            boxShadow={"0 4px 20px rgba(245, 14, 250, 0.6)"}
            onClick={handleLogin}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default StartSplash;
