import React from "react";
import { SharkLogoIcon } from "../constants/icons";
import Marquee from "react-fast-marquee";

const MarqueeItems = ({ items }) => {
  return (
    <div className="w-full" dir="ltr">
      <Marquee direction="left" autoFill={true} pauseOnClick={true} speed={40}>
        {items &&
          items.map((item, idx) => (
            <div
              key={idx}
              className={`border-2 rounded-lg p-4 flex justify-center gap-5 items-center mx-4`}
              style={{ borderColor: item.color }}
            >
              <div>
                <p className="text-white font-semibold">{item.title}</p>
              </div>
              <div className="text-white text-xl flex items-center gap-3">
                <span>{item.megs}</span>
                <SharkLogoIcon fill="#fff" width="30px" height="30px" />
              </div>
            </div>
          ))}
      </Marquee>
    </div>
  );
};

export default MarqueeItems;
