import { useAnimate } from "framer-motion";
import React, { useEffect, useState } from "react";
import useMeasure from "react-use-measure";
import { useDragControls, useMotionValue, motion } from "framer-motion";
import FinishIcon from "../assets/icons/Group@2x (2).svg";

import TaskConnect from "./TaskConnect";
import DailyReward from "./DailyReward";
const DragCloseDrawer = ({
  open,
  setOpen,
  title,
  description,
  data,
  reward,
  youtube,
  className,
  taskData,
  profile,
  rewardStreak,
  onRewardClaimed,
}) => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  const telegramId = userData && userData?.telegramId;
  const [scope, animate] = useAnimate();
  const [drawerRef, { height }] = useMeasure();

  const y = useMotionValue(0);
  const controls = useDragControls();

  const handleClose = async () => {
    animate(scope.current, {
      opacity: [1, 0],
    });

    const yStart = typeof y.get() === "number" ? y.get() : 0;

    await animate("#drawer", {
      y: [yStart, height],
    });

    setOpen(false);
  };

  return (
    <div>
      {open && (
        <motion.div
          ref={scope}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          onClick={handleClose}
          className="fixed inset-0 z-50 bg-black bg-opacity-25 backdrop-blur-sm "
        >
          <motion.div
            id="drawer"
            ref={drawerRef}
            onClick={(e) => e.stopPropagation()}
            initial={{ y: "100%" }}
            animate={{ y: "0%" }}
            transition={{
              ease: "easeInOut",
            }}
            className={`absolute bottom-0  w-full overflow-hidden rounded-t-3xl bg-[#3A386A] border border-[#00B2FF] ${className}`}
            style={{ y }}
            drag="y"
            dragControls={controls}
            onDragEnd={() => {
              if (y.get() >= 100) {
                handleClose();
              }
            }}
            dragListener={false}
            dragConstraints={{
              top: 0,
              bottom: 0,
            }}
            dragElastic={{
              top: 0,
              bottom: 0.5,
            }}
          >
            <div className="absolute left-0 right-0 top-0 z-10 flex justify-center bg-[#3A386A] p-4">
              <button
                onPointerDown={(e) => {
                  controls.start(e);
                }}
                className="h-2 w-14 cursor-grab touch-none rounded-full bg-white active:cursor-grabbing"
              ></button>
            </div>
            <div className="relative z-0 h-full overflow-y-scroll p-4 pt-12">
              <div className="flex justify-center items-center flex-col text-center font-aileronSemiBold gap-3">
                <h1 className="">{title}</h1>
                <p className=" text-[#9792CF]">{description}</p>
              </div>
              <div>
                {reward && (
                  <DailyReward
                    data={data}
                    rewardStreak={rewardStreak}
                    onRewardClaimed={onRewardClaimed}
                  />
                )}
              </div>
              <div>
                {youtube && (
                  <TaskConnect
                    link={taskData?.link}
                    _id={taskData?._id}
                    telegramId={telegramId}
                  />
                )}
              </div>
              <div>{profile && <Profile data={data} />}</div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default DragCloseDrawer;

export const Profile = ({ data }) => {
  const joinedDateStr = data?.youtube?.joinedDate;
  const joinedDate = joinedDateStr ? new Date(joinedDateStr) : null;

  const subscribers = data?.youtube?.subscribers || 0;
  const viewers = data?.youtube?.viewers || 0;
  let accountAge = 0;

  if (joinedDate && !isNaN(joinedDate)) {
    // Get current date
    const currentDate = new Date();

    // Calculate the total number of months difference
    const totalMonthsDifference =
      (currentDate.getFullYear() - joinedDate.getFullYear()) * 12 +
      (currentDate.getMonth() - joinedDate.getMonth());

    // Calculate how many months have passed after the year
    const monthsDifference =
      currentDate.getMonth() >= joinedDate.getMonth()
        ? currentDate.getMonth() - joinedDate.getMonth()
        : 12 - (joinedDate.getMonth() - currentDate.getMonth());

    // Display based on months or years
    if (totalMonthsDifference >= 12) {
      const years = Math.floor(totalMonthsDifference / 12);
      accountAge = `${years} years`;
    } else {
      accountAge = `${monthsDifference} months`;
    }
  }
  const finishedInfo = [
    {
      id: 1,
      icon: FinishIcon,
      title: `${accountAge} account age verified`,
    },
    {
      id: 2,
      icon: FinishIcon,
      title: ` ${subscribers} subscribes verified`,
    },
    {
      id: 3,
      icon: FinishIcon,
      title: `${viewers} views verified`,
    },
  ];
  return (
    <div className="pt-10 pb-12">
      <div className="grid grid-cols-3 gap-5">
        {finishedInfo.map((item, idx) => (
          <>
            <div
              key={idx}
              className="flex flex-col items-center gap-3  text-center "
            >
              <div className="rounded-full bg-gradient-to-b from-[#0094FF]/20 to-[#00B2FF]/20 flex justify-center items-center border border-[#00B2FF] w-[80px] h-[80px]">
                <img src={item.icon} alt="icon" className="p-1" />
              </div>
              <div className=" mx-2">
                <h1 className="text-white font-aileronSemiBold text-center iphone-x:text-sm iphone-11:text-[18px]">
                  {item.title}
                </h1>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};
