import React from "react";
import { motion } from "framer-motion";
import { MdOutlineArrowForwardIos } from "react-icons/md";

const GoldBox = ({
  icon,
  title,
  button,
  buttonText,
  onClick,
  subtitle,
  className,
  textClassName,
}) => {
  const iconVariants = {
    initial: {
      scale: 0.95, // Starting scale
    },
    animate: {
      y: [0, -5, 0], // Move up and down
      transition: {
        duration: 1.5,
        ease: "easeInOut",
        repeat: Infinity, // Loop indefinitely
      },
    },
  };
  return (
    <div className="w-full">
      <div
        className={`relative bg-gold-gradient rounded-xl ${className} h-[80px]`}
      >
        <div className="col-span-1">
          <motion.img
            src={icon}
            alt="reward"
            className="absolute left-0 bottom-0 z-10 w-24 h-24"
            variants={iconVariants}
            initial="initial"
            animate="animate"
          />
        </div>

        <div className={`flex flex-col ${textClassName} text-white`}>
          <h1 className="font-aileronSemiBold">{title}</h1>
          <p className="font-aileronRegular">{subtitle}</p>
        </div>
        {button && (
          <div className="flex justify-end pr-2 col-span-1">
            <button
              className="flex items-center font-aileronSemiBold"
              onClick={onClick}
            >
              {buttonText}
              <span className="pt-1">
                <MdOutlineArrowForwardIos />
              </span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default GoldBox;
