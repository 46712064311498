import React, { useEffect, useState } from "react";
import SharkBg from "../../assets/images/agree.jpg";
import PrimaryButton from "../../components/PrimaryButton";
import FinishIcon from "../../assets/icons/Group@2x (2).svg";
import { SharkLogoIcon } from "../../constants/icons";
import { useCreateResourceMutation } from "../../features/api/apiSlice";
const FinishedSplash = () => {
  //Local storage
  const userData = JSON.parse(localStorage.getItem("userData"));
  const telegramId = userData.telegramId;

  //states
  const [formData, setFormData] = useState({
    isCollectClicked: true,
    telegramId: telegramId,
  });
  // const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // // Update screen width on window resize
  // useEffect(() => {
  //   const handleResize = () => setScreenWidth(window.innerWidth);
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);
  const coins = userData?.coins || 0;
  const joinedDateStr = userData?.youtube?.joinedDate;
  const joinedDate = joinedDateStr ? new Date(joinedDateStr) : null;

  const subscribers = userData?.youtube?.subscribers || 0;
  const viewers = userData?.youtube?.viewers || 0;

  let accountAge = 0;

  if (joinedDate && !isNaN(joinedDate)) {
    // Get current date
    const currentDate = new Date();

    // Calculate the total number of months difference
    const totalMonthsDifference =
      (currentDate.getFullYear() - joinedDate.getFullYear()) * 12 +
      (currentDate.getMonth() - joinedDate.getMonth());

    // Calculate how many months have passed after the year
    const monthsDifference =
      currentDate.getMonth() >= joinedDate.getMonth()
        ? currentDate.getMonth() - joinedDate.getMonth()
        : 12 - (joinedDate.getMonth() - currentDate.getMonth());

    // Display based on months or years
    if (totalMonthsDifference >= 12) {
      const years = Math.floor(totalMonthsDifference / 12);
      accountAge = `${years} years`;
    } else {
      accountAge = `${monthsDifference} months`;
    }
  }

  //API
  const [createResource, { error, isLoading }] = useCreateResourceMutation();
  const finishedInfo = [
    {
      id: 1,
      icon: FinishIcon,
      title: `${accountAge} account age verified`,
    },
    {
      id: 2,
      icon: FinishIcon,
      title: ` ${subscribers} subscribes verified`,
    },
    {
      id: 3,
      icon: FinishIcon,
      title: `${viewers} views verified`,
    },
  ];
  const handleCollectCoins = async (e) => {
    e.preventDefault();
    try {
      const res = await createResource({
        url: "/user/updateYoutubeData",
        formData: { ...formData },
      }).unwrap();
    } catch (err) {
      console.log("err => ", err);
    }
    // Retrieve current userData
    const currentUserData = JSON.parse(localStorage.getItem("userData"));
    if (currentUserData) {
      // Update isCollectClicked to true
      currentUserData.youtube.isCollectClicked = true;
      // Save the updated data back to localStorage
      localStorage.setItem("userData", JSON.stringify(currentUserData));
    }
    window.location.reload(); // Refresh the app
    window.location.href = "/"; // Redirects to the home page of your app
  };
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  // Update screen width and height based on Telegram mini app container size
  useEffect(() => {
    const updateAppSize = () => {
      const appWidth =
        window.Telegram.WebApp.viewportWidth || window.innerWidth;
      const appHeight =
        window.Telegram.WebApp.viewportHeight || window.innerHeight;

      setScreenWidth(appWidth);
      setScreenHeight(appHeight);
    };

    // Initial call to set app size
    updateAppSize();

    // Listen for changes in mini app size (if the Telegram API triggers such updates)
    window.addEventListener("resize", updateAppSize);

    return () => {
      window.removeEventListener("resize", updateAppSize);
    };
  }, []);
  return (
    <div className="w-full bg-custom-gradient min-h-screen fixed inset-0">
      <div
        className="relative"
        style={{
          height: `${screenHeight * 0.6}px`,
          maxHeight: `${screenHeight * 0.6}px`,
        }}
      >
        <img src={SharkBg} alt="" className="w-full h-full object-cover" />
        <div className="absolute bg-gradient-to-b from-[#000000]/0 to-[#001539] bottom-0 right-0 left-0 z-10 h-32"></div>
        <div className="absolute bg-gradient-to-b from-[#000000]/0 to-[#001539] bottom-0 right-0 left-0 z-10 h-32"></div>
      </div>

      <div className=" bg-gradient-to-b from-[#001539] to-[#001539] h-full">
        <div className="grid grid-cols-3 gap-5">
          {finishedInfo.map((item, idx) => (
            <>
              <div
                key={idx}
                className="flex flex-col items-center gap-3  text-center "
              >
                <div className="rounded-full bg-gradient-to-b from-[#0094FF]/20 to-[#00B2FF]/20 flex justify-center items-center border border-[#00B2FF] w-[80px] h-[80px]">
                  <img src={item.icon} alt="icon" className="p-1" />
                </div>
                <div className=" mx-2">
                  <h1 className="text-white font-aileronSemiBold text-center iphone-x:text-sm iphone-11:text-[18px]">
                    {item.title}
                  </h1>
                </div>
              </div>
            </>
          ))}
        </div>
        <div className="fixed bottom-10 right-0 left-0 mx-5">
          <PrimaryButton
            type={"button"}
            text={"Collect"}
            className={" bg-gradient-to-r from-[#4952E5] to-[#CC5DCF] p-4 "}
            boxShadow={"0 4px 20px rgba(245, 14, 250, 0.6)"}
            onClick={handleCollectCoins}
            icon={<SharkLogoIcon fill="#fff" width="35px" height="21px" />}
            coins={coins}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default FinishedSplash;
