import React from "react";
import { SharkLogoIcon } from "../constants/icons";

const Lists = ({
  title,
  isSubtitle,
  data,
  subtitle,
  getFirstLetter,
  userData,
  icon,
}) => {
    return (
    <div className="w-full">
      <div className="mt-2">
        <div className="flex justify-between items-center pl-2 pr-6 py-3">
          <div className="flex items-center gap-5">
            <div className="bg-gradient-to-br from-[#C05ED2] to-[#5153E5] p-3 rounded-xl w-[54px] h-[54px]">
              <p className="text-center py-auto">{getFirstLetter(userData)}</p>
            </div>
            <div className="flex flex-col items-start text-white ">
              <h1 className="font-aileronSemiBold">{title}</h1>
              {isSubtitle && <p>{subtitle}</p>}
            </div>
          </div>
          <div className="flex items-center gap-3">
            <p className="text-[#9792CF]">{data}</p>
            {icon && <SharkLogoIcon fill="#fff" width="30px" height="30px" />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lists;
