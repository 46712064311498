import React, { useEffect, useState } from "react";

import GiftIcon from "../../assets/images/Asset 2@3x 1.png"; // Adjust path accordingly
import { SharkLogoIcon } from "../../constants/icons";
import PrimaryButton from "../../components/PrimaryButton";
import { useCreateResourceMutation } from "../../features/api/apiSlice";

const Welcome = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const telegramId = userData?.telegramId;

  const [randomNumber, setRandomNumber] = useState(0);
  const [formData, setFormData] = useState({
    telegramId: telegramId,
    coins: 0,
  });

  useEffect(() => {
    const generateRandomNumber = () =>
      Math.floor(Math.random() * (1500 - 100 + 1)) + 100;
    const number = generateRandomNumber();
    setRandomNumber(number);
    setFormData((prevData) => ({
      ...prevData,
      coins: number,
    }));
  }, [telegramId]);

  const [createResource, { isLoading, error }] = useCreateResourceMutation();
  const HandleStart = async (e) => {
    e.preventDefault();

    try {
      const res = await createResource({
        url: "/user/updateUserCoins",
        formData: formData,
      });

      if (res.data) {
        window.location.replace("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full bg-custom-gradient min-h-screen fixed inset-0">
      <div className="flex h-full w-full flex-col items-center justify-center gap-16">
        <div>
          <img src={GiftIcon} alt="Gift Icon" />
        </div>
        <div className="flex items-center gap-3 text-white">
          <p className="font-aileronSemiBold text-2xl tracking-wider">
            +{randomNumber}
          </p>
          <SharkLogoIcon fill="#fff" width="52px" height="32px" />
        </div>

        <div className="flex flex-col items-center gap-5">
          <h1 className="text-4xl font-aileronSemiBold text-white tracking-wider">
            Welcome!
          </h1>
          <p className="tracking-wide text-center mx-5 text-xl text-[#9792CF]">
            You have been rewarded with {randomNumber} Megs for joining the
            community.
          </p>
        </div>

        <div className="fixed bottom-10 right-0 left-0 mx-5 z-50">
          <PrimaryButton
            type={"button"}
            text={"START"}
            className={"bg-gradient-to-r from-[#4952E5] to-[#CC5DCF] p-4"}
            boxShadow={"0 4px 20px rgba(245, 14, 250, 0.6)"}
            onClick={HandleStart}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Welcome;
