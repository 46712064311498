import React, { useEffect, useState } from "react";
import Background from "../assets/images/Holding controll.jpg";
import GamingImg from "../assets/icons/Firefly Create an icon representing the play-to-earn concept, specifically for mobile gaming, with a 1.svg";
const Games = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  // Update screen width and height based on Telegram mini app container size
  useEffect(() => {
    const updateAppSize = () => {
      const appWidth =
        window.Telegram.WebApp.viewportWidth || window.innerWidth;
      const appHeight =
        window.Telegram.WebApp.viewportHeight || window.innerHeight;

      setScreenWidth(appWidth);
      setScreenHeight(appHeight);
    };

    // Initial call to set app size
    updateAppSize();

    // Listen for changes in mini app size (if the Telegram API triggers such updates)
    window.addEventListener("resize", updateAppSize);

    return () => {
      window.removeEventListener("resize", updateAppSize);
    };
  }, []);
  return (
    <div className="w-full">
      <div
        className="relative"
        style={{
          height: `${screenHeight * 0.7}px`,
          maxHeight: `${screenHeight * 0.7}px`,
        }}
      >
        <img src={Background} alt="" className="w-full h-full object-cover" />
        <div className="absolute bg-gradient-to-b from-[#000000]/0 to-[#001539] bottom-0 right-0 left-0 z-10 h-10"></div>
        <div className="absolute bg-gradient-to-b from-[#000000]/0 to-[#001539] bottom-0 right-0 left-0 z-10 h-10"></div>
      </div>

      <div className="bg-gradient-to-b from-[#001539] to-[#001539] pt-8 pb-24 px-5">
        <div className="bg-gold-gradient flex  rounded-xl px-2 py-2 text-white">
          <img src={GamingImg} alt="" />
          <div className="flex flex-col">
            <div className="px-3 ">
              <h1 className="font-aileronSemiBold iphone-11:text-xl">
                Play Our Games, enjoy, and earn
              </h1>
            </div>
            <div className=" flex justify-end iphone-x:mt-10 px-4">
              <p className="text-[12px] font-aileronRegular">Coming Soon...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Games;
