import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

//https://megsapi.megskombat.com/
//https://megskombat-test-bfh0grate8hdg3gp.westeurope-01.azurewebsites.net
// Base Query
const baseQuery = fetchBaseQuery({
  baseUrl: "https://megsapi.megskombat.com/api/v1",
  credentials: "include",
});

const API_KEY = process.env.REACT_APP_API_KEY;

// Helper function to build query string
const buildQueryString = (params) => {
  return new URLSearchParams(params).toString();
};

// API Slice
export const apiSlice = createApi({
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    createResource: builder.mutation({
      query: ({ url, body, formData, params }) => ({
        url: `${url}${params ? `?${buildQueryString(params)}` : ""}`,
        method: "POST",
        body: {
          ...(body ? { ...body } : formData), // Spread body if present, else use formData
          key: API_KEY, // Include the API key in the body
        },
        formData: formData ? true : false,
      }),
      invalidatesTags: [{ type: "Resource" }],
    }),
    getResource: builder.query({
      query: ({ url, params }) =>
        `${url}${params ? `?${buildQueryString(params)}` : ""}`,
      providesTags: [{ type: "Resource" }],
    }),
  }),
});

export const {
  useCreateResourceMutation,
  useLazyGetResourceQuery,
  useGetResourceQuery,
} = apiSlice;
