import { initUtils, initHapticFeedback } from "@telegram-apps/sdk";
import React, { useEffect, useState } from "react";
import PrimaryButton from "./PrimaryButton";
import { useCreateResourceMutation } from "../features/api/apiSlice";
import toast from "react-hot-toast";

const TaskConnect = ({ link, _id, telegramId }) => {
  const hapticFeedback = initHapticFeedback();
  const utils = initUtils();
  const [isClicked, setIsClicked] = useState(false);
  const [timer, setTimer] = useState(null);
  const [showConnectButton, setShowConnectButton] = useState(true);
  const [isCheckClicked, setIsCheckClicked] = useState(false); // Track if Check button is clicked
  const [isConnectClicked, setIsConnectClicked] = useState(false); // Track if Connect button is clicked
  const [formData, setFormData] = useState({
    telegramId: telegramId,
    taskId: _id,
  });

  useEffect(() => {
    const storedTimer = localStorage.getItem(`countdownTimer_${_id}`);
    const storedTimestamp = localStorage.getItem(`countdownTimestamp_${_id}`);
    const isConnected = localStorage.getItem(`taskConnected_${_id}`);
    const checkClicked = localStorage.getItem(`checkClicked_${_id}`);

    if (checkClicked) {
      setIsCheckClicked(true);
    }

    if (isConnected) {
      setShowConnectButton(false);
      setIsConnectClicked(true); // Ensure Check button is enabled on mount
      return;
    }

    if (storedTimer && storedTimestamp) {
      const timePassed = Math.floor((Date.now() - storedTimestamp) / 1000);
      const remainingTime = storedTimer - timePassed;

      if (remainingTime > 0) {
        setTimer(remainingTime);
        setIsClicked(true);
      } else {
        setTimer(0);
        setShowConnectButton(false);
        setIsConnectClicked(true); // Enable the Check button
        localStorage.removeItem(`countdownTimer_${_id}`);
        localStorage.removeItem(`countdownTimestamp_${_id}`);
        localStorage.setItem(`taskConnected_${_id}`, true);
      }
    } else {
      setShowConnectButton(true);
    }
  }, [_id]);

  useEffect(() => {
    let interval;
    if (isClicked && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          const newTime = prevTimer - 1;
          if (newTime <= 0) {
            clearInterval(interval);
            setTimer(0);
            setShowConnectButton(false);
            setIsConnectClicked(true); // Enable the Check button
            localStorage.removeItem(`countdownTimer_${_id}`);
            localStorage.removeItem(`countdownTimestamp_${_id}`);
            localStorage.setItem(`taskConnected_${_id}`, true);
            return 0;
          }
          return newTime;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isClicked, _id]);

  const handleConnect = async () => {
    hapticFeedback.impactOccurred("soft");
    if (isClicked && timer > 0) {
      utils.openLink(link, { tryBrowser: true });
      return;
    }

    setIsClicked(true);
    const countdownTime = 3600; // 1 hours countdown in seconds
    setTimer(countdownTime);
    utils.openLink(link, { tryBrowser: true });

    localStorage.setItem(`countdownTimer_${_id}`, countdownTime);
    localStorage.setItem(`countdownTimestamp_${_id}`, Date.now());
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor((seconds % 3600) / 60);
    const hours = Math.floor(seconds / 3600);
    const remainingSeconds = seconds % 60;

    if (hours > 0) {
      return hours === 1
        ? `${hours} hour ${minutes} minutes`
        : `${hours} hours ${minutes} minutes`;
    } else if (minutes > 0) {
      return `${minutes} minutes`;
    } else {
      return `${remainingSeconds} seconds`;
    }
  };

  const [createResource, { isLoading, error }] = useCreateResourceMutation();
  const handleCheck = async () => {
    if (isCheckClicked) return; // Prevent multiple clicks

    toast.dismiss();
    try {
      const res = await createResource({
        url: "/user/assignTaskToUser",
        formData: formData,
      });
      if (res) {
        toast.success("Task successfully checked", {
          icon: null,
          style: {
            backgroundColor: "whitesmoke",
            color: "black",
            padding: "0.75rem",
            borderRadius: "9999px",
            boxShadow: "0 10px 15px rgba(0, 0, 0, 0.1)",
          },
        });
        hapticFeedback.notificationOccurred("success");
        const userData = JSON.parse(localStorage.getItem("userData"));
        userData.coins = res.data?.data?.user?.coins;
        localStorage.setItem("userData", JSON.stringify(userData));
        // Mark Check button as clicked and disable it
        setIsCheckClicked(true);
        localStorage.setItem(`checkClicked_${_id}`, true); // Store flag in localStorage
      }
    } catch (err) {
      console.log("err =>", err);
    }
  };

  return (
    <div className="w-full">
      <div className="flex flex-col justify-center items-center gap-5 py-5">
        {showConnectButton && ( // Conditionally render the Connect button
          <PrimaryButton
            type={"button"}
            text={"Connect"}
            className={`bg-gradient-to-r from-[#4952E5] to-[#CC5DCF] p-4`}
            boxShadow={"0 4px 20px rgba(245, 14, 250, 0.6)"}
            onClick={handleConnect}
          />
        )}

        <PrimaryButton
          type={"button"}
          text={"Check"}
          className={`${
            isCheckClicked || timer > 0
              ? "bg-gray-500 text-white"
              : "bg-[#3A386A] border border-[#179DE8]"
          } p-4`}
          onClick={handleCheck}
          disabled={isCheckClicked || !isConnectClicked || timer > 0} // Disable when timer is active or connect not clicked or already clicked
          timer={formatTime(timer)}
          isTimerCheck={timer}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default TaskConnect;
