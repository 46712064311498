// Loading.js
import { motion } from "framer-motion";

const LoadingIcon = () => {
  return (
    <div className="">
      <BarLoader />
    </div>
  );
};

const variants = {
  initial: {
    scaleY: 0.5,
    opacity: 0.3,
  },
  animate: {
    scaleY: 1,
    opacity: 1,
    transition: {
      repeat: Infinity,
      repeatType: "mirror",
      duration: 0.8,
      ease: "easeInOut",
    },
  },
};

const BarLoader = () => {
  return (
    <motion.div
      transition={{
        staggerChildren: 0.25,
      }}
      initial="initial"
      animate="animate"
      className="flex gap-1"
    >
      <motion.div
        variants={variants}
        className="h-10 w-2 bg-white rounded-xl "
      />
      <motion.div
        variants={variants}
        className="h-10 w-2 bg-white rounded-xl"
      />
      <motion.div
        variants={variants}
        className="h-10 w-2 bg-white rounded-xl"
      />
    </motion.div>
  );
};
export default LoadingIcon;
