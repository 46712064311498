import React from "react";
import PageTitle from "../components/PageTitle";
import Lists from "../components/Lists";
import {
  useCreateResourceMutation,
  useGetResourceQuery,
} from "../features/api/apiSlice";
import Top1 from "../assets/icons/top1.svg";
import Top2 from "../assets/icons/top2.svg";
import Top3 from "../assets/icons/top3.svg";
import Skeleton from "react-loading-skeleton";

const Rank = () => {
  //Local storage
  const userData = JSON.parse(localStorage.getItem("userData"));
  const telegramId = userData.telegramId;

  //states
  if (!telegramId) {
    console.error("Telegram ID is missing.");
    return <div>Error: Telegram ID is missing.</div>;
  }

  // API request
  const {
    data,
    error,
    isLoading: loading,
    isFetching,
  } = useGetResourceQuery({
    url: `/user/getLeaderboardAndRank/${telegramId}`,
  });
  const renderRankIcon = (rank) => {
    switch (rank) {
      case 1:
        return <img src={Top1} alt="top1" />;
      case 2:
        return <img src={Top2} alt="top1" />;
      case 3:
        return <img src={Top3} alt="top1" />;
      default:
        return `#${rank}`;
    }
  };

  const getFirstLetter = (userData) => {
    if (userData.username) {
      return userData.username.charAt(0).toUpperCase();
    } else if (userData.firstName || userData.lastName) {
      return `${userData.firstName?.charAt(0) || ""}${
        userData.lastName?.charAt(0) || ""
      }`.toUpperCase();
    }
    return "U"; // Fallback if no username, first name, or last name exists
  };
  return (
    <div className="w-full">
      <PageTitle
        title={"Leaderboard"}
        subtitle={"Look at our top 100 heroes!"}
        description={
          loading ? (
            <Skeleton count={1} />
          ) : (
            `${data?.data?.numberOfHolders} holders`
          )
        }
      />
      {/*Ranking */}
      <div className="p-5">
        <div className="bg-gradient-to-b from-[#0094FF]/5 to-[#00B2FF]/20 p-5 flex justify-between items-center text-[#9792CF]/60 rounded-xl border border-[#00B2FF]">
          <h1>User</h1>
          <h1>Rank</h1>
        </div>
        <div className="pb-28">
          {loading
            ? [...Array(data?.data?.topUsers?.length || 3)].map((_, idx) => (
                <div key={idx} className="my-4">
                  <Skeleton
                    height={50}
                    className="bg-black opacity-30 backdrop-blur-sm rounded-xl"
                  />
                </div>
              ))
            : data?.data?.topUsers.map((item, idx) => (
                <Lists
                  key={idx}
                  title={
                    item.username
                      ? item.username
                      : `${item.firstName} ${item.lastName}`
                  }
                  userData={item}
                  isSubtitle={true}
                  data={renderRankIcon(idx + 1)}
                  subtitle={`${Math.floor(item.coins)} megs`}
                  getFirstLetter={getFirstLetter}
                />
              ))}
        </div>

        <div className="fixed bottom-24 right-0 left-0 mx-4 bg-[#9792CF] bg-opacity-25 backdrop-blur-sm rounded-lg">
          <div className="flex justify-between items-center pl-2 pr-6 py-3">
            <div className="flex items-center gap-5">
              <div className="bg-gradient-to-br from-[#C05ED2] to-[#5153E5] p-3 rounded-xl w-[54px] h-[54px]">
                <p className="text-center py-auto ">
                  {getFirstLetter(userData)}
                </p>
              </div>
              <div className="flex flex-col items-start text-white ">
                <h1 className="font-aileronSemiBold">{userData?.username}</h1>
                <p className="text-[#9792CF]">{Math.floor(userData?.coins)}</p>
              </div>
            </div>
            <div>
              <h1 className="text-[#9792CF]">
                {data && renderRankIcon(data?.data?.userRank)}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rank;
