import { initHapticFeedback } from "@telegram-apps/sdk";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import DragCloseDrawer from "./DragCloseDrawer";
import { IoIosArrowForward } from "react-icons/io";
const DropDown = ({ isOpen, setIsDropDownOpen }) => {
  // Motion variants for dropdown open/close animation
  const dropdownVariants = {
    hidden: {
      opacity: 0,
      y: -10,
      transition: {
        duration: 0.2,
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      opacity: 0,
      y: -10,
      transition: {
        duration: 0.3,
      },
    },
  };

  const userData = JSON.parse(localStorage.getItem("userData"));
  const isConnected = userData?.youtube?.isConnected;
  const hapticFeedback = initHapticFeedback();

  const [openProfile, setOpenProfile] = useState(false);

  const handleOpenProfile = (e) => {
    hapticFeedback.impactOccurred("soft");
    e.preventDefault();
    setOpenProfile(true);
  };

  const openPrivacyPolicy = () => {
    if (window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink("https://megskombat.com/PrivacyPolicy");
    } else {
      window.open("https://megskombat.com/PrivacyPolicy", "_blank");
    }
  };
  const openTermsOfService = () => {
    if (window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink("https://megskombat.com/termsofservice");
    } else {
      window.open("https://megskombat.com/termsofservice", "_blank");
    }
  };
  return (
    <div className="inline-block text-left">
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={dropdownVariants}
            className="w-48 bg-[#282F78]/30 backdrop-blur-md text-white rounded-md shadow-lg z-10"
          >
            <ul className="py-2">
              <li
                className=" py-2  cursor-pointer flex justify-between items-center  border-b  border-[#A5A9FF] pb-2 mx-2"
                onClick={ handleOpenProfile}
             
              >
                <h1 className="">Profile</h1>
                <IoIosArrowForward className="text-[#A5A9FF]" size={18} />
              </li>
              <li
                className=" py-2  cursor-pointer flex justify-between items-center  border-b  border-[#A5A9FF] pb-2 mx-2"
                onClick={openPrivacyPolicy}
              >
                <p className="text-[14px] font-medium">Privacy and Policy</p>
                <IoIosArrowForward className="text-[#A5A9FF]" size={18} />
              </li>
              <li
                className="px-2 py-2  cursor-pointer flex justify-between items-center"
                onClick={openTermsOfService}
              >
                <p className="text-[14px] font-medium text-shadow-md">
                  Terms and Conditions
                </p>
                <IoIosArrowForward className="text-[#A5A9FF]" size={18} />
              </li>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>

      {openProfile && (
        <DragCloseDrawer
          open={openProfile}
          setOpen={setOpenProfile}
          title={
            userData.username
              ? userData.username
              : `${userData.firstName} ${userData.lastName}`
          }
          data={userData}
          profile={true}
          className={"h-[55vh]"}
        />
      )}
    </div>
  );
};

export default DropDown;
