import React, { useEffect, useState } from "react";
import Tasks from "../components/Tasks";
import chest from "../assets/icons/treasure.svg";
import {
  BigSharkIcon,
  InviteIcon,
  RewardIcon,
  TaskIcon,
} from "../constants/icons";
import GoldBox from "../components/GoldBox";
import DragCloseDrawer from "../components/DragCloseDrawer";
import { useGetResourceQuery } from "../features/api/apiSlice";
import Skeleton from "react-loading-skeleton";
import { initHapticFeedback, initCloudStorage } from "@telegram-apps/sdk";
import { useNavigate } from "react-router-dom";
import ConnectYoutube from "../components/ConnectYoutube";

const Home = () => {
  const cloudStorage = initCloudStorage();
  const hapticFeedback = initHapticFeedback();
  const navigate = useNavigate();

  // State variables
  const [dataFromCloud, setDataFromCloud] = useState(null);
  const [storedEndTime, setStoredEndTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState("00:00:00");
  const [taskData, setTaskData] = useState({});
  const [rewardOpen, setRewardOpen] = useState(false);
  const [taskOpen, setTaskOpen] = useState(false);

  // Fetch user data from cloudStorage
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDataCloud = await cloudStorage.get("userData");
        const data = JSON.parse(userDataCloud);
        setDataFromCloud(data);
        console.log("data :", data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  // Local storage
  const userData = JSON.parse(localStorage.getItem("userData"));
  const telegramId = userData?.telegramId;

  const isConnected = userData?.youtube?.isConnected;
  const isCollected = userData?.youtube?.isCollectClicked;

  // Fetch storedEndTime from cloudStorage
  useEffect(() => {
    const fetchStoredEndTime = async () => {
      if (telegramId) {
        try {
          const endTimeString = await cloudStorage.get(
            `countdownEndTime_${telegramId}`
          );
          if (endTimeString) {
            setStoredEndTime(endTimeString);
          }
        } catch (error) {
          console.error(
            "Error fetching storedEndTime from cloudStorage:",
            error
          );
        }
      }
    };

    fetchStoredEndTime();
  }, [telegramId]);

  // Redirect logic
  useEffect(() => {
    if (!isConnected) {
      return;
    } else if (!isCollected) {
      navigate("/Finished");
    }
  }, [isConnected, isCollected, navigate]);

  // Function to calculate remaining time
  const calculateRemainingTime = (endTime) => {
    const now = new Date();

    // Check if endTime is a valid date
    if (isNaN(endTime.getTime())) {
      return "00:00:00";
    }

    const remainingTime = endTime - now;

    if (remainingTime <= 0) {
      return "00:00:00"; // Countdown finished
    }

    const hours = Math.floor(remainingTime / (1000 * 60 * 60));
    const minutes = Math.floor(
      (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

    // Pad hours, minutes, and seconds with leading zeros
    const hoursStr = String(hours).padStart(2, "0");
    const minutesStr = String(minutes).padStart(2, "0");
    const secondsStr = String(seconds).padStart(2, "0");

    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  };

  // Update remainingTime based on storedEndTime
  useEffect(() => {
    let interval;

    if (storedEndTime) {
      interval = setInterval(() => {
        const endTime = new Date(storedEndTime);
        const newRemainingTime = calculateRemainingTime(endTime);
        setRemainingTime(newRemainingTime);

        if (newRemainingTime === "00:00:00") {
          clearInterval(interval);
          setStoredEndTime(null);
        }
      }, 1000);
    } else {
      setRemainingTime("00:00:00");
    }

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [storedEndTime]);

  // Fetch tasks data
  const { data: tasks, isLoading: isTasksLoading } = useGetResourceQuery({
    url: `/tasks/getUnperformedTasks/${telegramId}`,
  });
  const {
    data: performedTasks,
    isLoading: isPerformedTasksLoading,
    isFetching: isPerformedTasksFetching,
  } = useGetResourceQuery({
    url: `/user/getUserTasks/${telegramId}`,
  });

  const handleOpenRewards = async (e) => {
    hapticFeedback.impactOccurred("soft");
    e.preventDefault();
    setRewardOpen(true);
  };

  const handleOpenTask = (data) => {
    hapticFeedback.impactOccurred("soft");
    setTaskOpen(true);
    setTaskData(data);
  };

  const homeInfo = [
    {
      id: 1,
      title: "Rewards",
      icon: <RewardIcon />,
      data: Math.floor(userData?.rewardStreak) || 0,
    },
    {
      id: 2,
      title: "Tasks",
      icon: <TaskIcon />,
      data: performedTasks ? performedTasks?.data?.tasks?.length : "Loading...",
    },
    {
      id: 3,
      title: "Invites",
      icon: <InviteIcon />,
      data: userData ? userData?.referredFriends.length : 0,
    },
  ];

  const rewardData = [
    {
      id: 1,
      coin: `+${100}`,
      title: "Day 1",
    },
    {
      id: 2,
      coin: `+${300}`,
      title: "Day 2",
    },
    {
      id: 3,
      coin: `+${650}`,
      title: "Day 3",
    },
    {
      id: 4,
      coin: `+${1250}`,
      title: "Day 4",
    },
    {
      id: 5,
      coin: `+${2100}`,
      title: "Day 5",
    },
    {
      id: 6,
      coin: `+${3950}`,
      title: "Day 6",
    },
    {
      id: 7,
      coin: `+${5350}`,
      title: "Day 7",
    },
    {
      id: 8,
      coin: `+${7500}`,
      title: "Day 8",
    },
    {
      id: 9,
      coin: `+${10000}`,
      title: "Day 9",
    },
    {
      id: 10,
      coin: `+${15000}`,
      title: "Day 10",
    },
  ];
  // In Home component
  const handleRewardClaimed = async () => {
    if (telegramId) {
      try {
        // Re-fetch storedEndTime
        const endTimeString = await cloudStorage.get(
          `countdownEndTime_${telegramId}`
        );
        if (endTimeString) {
          setStoredEndTime(endTimeString);
        } else {
          setStoredEndTime(null);
        }

        // Re-fetch dataFromCloud (userData)
        const userDataString = await cloudStorage.get("userData");
        if (userDataString) {
          const data = JSON.parse(userDataString);
          setDataFromCloud(data);
        }
      } catch (error) {
        console.error("Error fetching data from cloudStorage:", error);
      }
    }
  };

  return (
    <div className="w-full h-full text-white">
      <div className="flex flex-col justify-center items-center">
        {!isConnected && <ConnectYoutube />}

        <div className="w-full">
          <div className="flex justify-center items-center flex-col gap-5 pt-10">
            <BigSharkIcon fill="#fff" width="100.59px" height="114px" />
            <h1
              className="tracking-wider text-[36px] text-[#fff]"
              style={{ textShadow: "0 2px 30px #fff" }}
            >
              <span className="tracking-wide px-2">
                {Math.floor(userData?.coins)}
              </span>
              MEGS
            </h1>
          </div>

          <div className="bg-gradient-to-b from-[#0094FF]/0 to-[#00B2FF]/45 rounded-md m-5">
            <div className="grid grid-cols-3">
              {homeInfo.map((info, idx) => (
                <div key={idx} className="flex flex-col items-center">
                  <span>{info.icon}</span>
                  <p className="text-[#9792CF] pt-2 font-aileronThin tracking-wider text-sm">
                    <span className="px-1 ">{info.data}</span>
                    {info.title}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className="pt-3 m-5">
            <h1 className="text-xl font-aileronSemiBold py-4">Daily Reward</h1>
            <GoldBox
              icon={chest}
              title={"Hurry up and get it"}
              subtitle={remainingTime}
              button={true}
              buttonText={"Claim"}
              onClick={handleOpenRewards}
              className={"grid grid-cols-4 items-center"}
              textClassName={"pl-3 col-span-2 "}
            />
          </div>
        </div>

        {/* Tasks */}

        {isTasksLoading ? (
          <>
            <div className="w-full my-10 px-5">
              {Array.from({
                length: tasks?.data?.tasks?.length || 3,
              }).map((_, idx) => (
                <Skeleton
                  key={idx}
                  height={70}
                  className="bg-black opacity-30 backdrop-blur-sm rounded-xl my-2"
                />
              ))}
            </div>
          </>
        ) : (
          tasks?.data?.tasks?.length > 0 && (
            <div className="w-full my-10">
              <h1 className="px-5 text-2xl">Tasks</h1>
              {tasks.data.tasks.map((item, idx) => (
                <Tasks
                  key={idx}
                  onClick={() => handleOpenTask(item)}
                  title={item.title}
                  coinReward={item.coinReward}
                  icon={item.socialMediaTaskName}
                />
              ))}
            </div>
          )
        )}

        {/* Complete Tasks */}

        {isPerformedTasksLoading || isPerformedTasksFetching ? (
          <>
            {/* Display skeletons based on the task count or a default number */}
            {Array.from({
              length: performedTasks?.data?.tasks?.length || 3,
            }).map((_, idx) => (
              <Skeleton
                key={idx}
                height={50}
                className="bg-black opacity-30 backdrop-blur-sm rounded-xl my-2"
              />
            ))}
          </>
        ) : (
          performedTasks?.data?.tasks?.length > 0 && (
            <div className="w-full my-10">
              <h1 className="px-5 text-2xl">Performed tasks</h1>

              {performedTasks.data.tasks.map((item, idx) => (
                <Tasks
                  isPerformed={true}
                  key={idx}
                  disabled={true}
                  title={item.title}
                  coinReward={item.coinReward}
                  icon={item.socialMediaTaskName}
                />
              ))}
            </div>
          )
        )}
      </div>

      {rewardOpen && (
        <DragCloseDrawer
          open={rewardOpen}
          setOpen={setRewardOpen}
          title={"Daily rewards"}
          description={
            "Claim your daily reward every day and expand your earnings!"
          }
          data={rewardData}
          reward={true}
          className={"h-[80vh]"}
          onRewardClaimed={handleRewardClaimed} // Pass the callback here
          rewardStreak={userData?.rewardStreak}
        />
      )}

      {taskOpen && (
        <DragCloseDrawer
          open={taskOpen}
          setOpen={setTaskOpen}
          title={taskData?.title}
          description={taskData?.description}
          youtube={true}
          className={"iphone-x:h-[65vh] iphone-11:h-[60vh]"}
          taskData={taskData}
        />
      )}
    </div>
  );
};

export default Home;