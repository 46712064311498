import React from "react";
import Youtube from "../assets/icons/yuotube icon.svg";
import X from "../assets/icons/X icon.svg";
import telegram from "../assets/icons/telegram.svg";
import { ArrowLeft } from "../constants/icons";

const Tasks = ({ onClick, coinReward, title, disabled, isPerformed, icon }) => {
  const renderIcon = () => {
    if (icon.toLowerCase().includes("youtube")) {
      return <img src={Youtube} alt="YouTube Icon" />;
    } else if (icon.toLowerCase().includes("x")) {
      return <img src={X} alt="X Icon" />;
    } else if (icon.toLowerCase().includes("telegram")) {
      return <img src={telegram} alt="telegram Icon" />;
    } else {
      return null; // In case no valid icon is passed
    }
  };
  return (
    <div className="border h-[85px]  m-5 bg-[#8F8DCA]/10 border-[#AA17DC] rounded-xl">
      <div className="grid grid-cols-6 w-full h-full ">
        <div className="flex items-center col-span-1">{renderIcon()}</div>
        <div className="w-full col-span-3 py-2">
          <p className="w-full text-md line-clamp-2">{title}</p>
          <span className="text-[#9792CF]">{coinReward} Megs</span>
        </div>
        <div className="text-md flex items-center justify-end   col-span-2">
          {isPerformed ? (
            <button
              disabled={disabled}
              type="button"
              className="flex items-center text-transparent bg-clip-text bg-gradient-to-b from-[#AA17DC] to-[#179DE8] pr-3"
              onClick={onClick}
            >
              Performed
            </button>
          ) : (
            <button
              type="button"
              className="flex items-center text-transparent bg-clip-text bg-gradient-to-b from-[#AA17DC] to-[#179DE8]"
              onClick={onClick}
            >
              Perform
              <span className="pt-1">
                <ArrowLeft />
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Tasks;
