import React from "react";

const PageTitle = ({ title, subtitle, description }) => {
  return (
    <div className="w-full">
      <div className="flex flex-col items-center gap-2 my-10">
        <h1 className="text-2xl text-white font-aileronSemiBold">{title}</h1>
        <p className="text-md text-[#9792CF] font-aileronLight">{subtitle}</p>
        {/* <span className="font-aileronSemiBold text-white text-sm">
          {description && description}
        </span> */}
      </div>
    </div>
  );
};

export default PageTitle;
