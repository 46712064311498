import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "../pages/Home";
import Layout from "../layout/Layout";
import Rank from "../pages/Rank";
import Invite from "../pages/Invite";
import Games from "../pages/Games";
import StartSplash from "../pages/Splashs/StartSplash";
import LoadingSplash from "../pages/Splashs/LoadingSplash";
import FinishedSplash from "../pages/Splashs/FinishedSplash";
import RequireAuth from "../features/auth/requireAuth";
import TelegramError from "../pages/Splashs/TelegramError";
import VerifyYoutubeData from "../pages/VerifyYoutubeData";
import NotFound from "../pages/404";
import Welcome from "../pages/Splashs/Welcome";

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<RequireAuth />}>
        <Route element={<Layout />}>
          <Route path="/" index element={<Home />} />
          <Route path="/Rank" element={<Rank />} />
          <Route path="/Games" element={<Games />} />
          <Route path="/InviteFriends" element={<Invite />} />
        </Route>
      </Route>

      <Route path="/Splash" element={<StartSplash />} />
      <Route path="/Loading" element={<LoadingSplash />} />
      <Route path="/Finished" element={<FinishedSplash />} />
      <Route path="/Error" element={<TelegramError />} />
      <Route path="/Welcome" element={<Welcome />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
