import React from "react";
import TopBar from "./TopBar";
import BottomBar from "./BottomBar";
import { Outlet, useLocation } from "react-router-dom";

const Layout = () => {
  const location = useLocation();
  const path = location.pathname;
  return (
    <div className=" bg-custom-gradient min-h-screen flex flex-col">
      <TopBar />
      <div
        className={`${
          path === "/Games" ? "" : "flex-grow flex  flex-col justify-start items-start py-10"
        }`}
      >
        <Outlet />
      </div>
      <BottomBar />
    </div>
  );
};

export default Layout;
