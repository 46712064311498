import {
  ControlLinkIcon,
  InviteLinkIcon,
  RankLinkIcon,
  SharkLinkIcon,
} from "./icons";
export const bottomLinks = [
  {
    id: 1,
    icon: (
      <SharkLinkIcon fill="currentColor" width="33.54px" height="31.68px" />
    ),
    link: "/",
  },
  {
    id: 2,
    icon: <RankLinkIcon fill="currentColor" width="33.54px" height="34.68px" />,
    link: "/Rank",
  },
  {
    id: 3,
    icon: (
      <ControlLinkIcon fill="currentColor" width="33.54px" height="34.68px" />
    ),
    link: "/Games",
  },
  {
    id: 4,
    icon: (
      <InviteLinkIcon fill="currentColor" width="33.54px" height="34.68px" />
    ),
    link: "/InviteFriends",
  },
];

export const InviteFriendData = [
  {
    id: 1,
    title: "5 referred friends",
    megs: "500",
    color: "#33327A",
  },
  {
    id: 2,
    title: "10 referred friends",
    megs: "1000",
    color: "#AA17DC",
  },
  {
    id: 3,
    title: "20 referred friends",
    megs: "1500 ",
    color: "#71351D",
  },
  {
    id: 4,
    title: "50 referred friends",
    megs: "5000",
    color: "#9E9FA1",
  },
];
