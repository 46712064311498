import React, { useEffect, useState } from "react";
import SharkBg from "../../assets/images/Holding 2 balls.jpg";
import LoadingIcon from "../../components/LoadingIcon";
const LoadingSplash = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  // Update screen width and height based on Telegram mini app container size
  useEffect(() => {
    const updateAppSize = () => {
      const appWidth =
        window.Telegram.WebApp.viewportWidth || window.innerWidth;
      const appHeight =
        window.Telegram.WebApp.viewportHeight || window.innerHeight;

      setScreenWidth(appWidth);
      setScreenHeight(appHeight);
    };

    // Initial call to set app size
    updateAppSize();

    // Listen for changes in mini app size (if the Telegram API triggers such updates)
    window.addEventListener("resize", updateAppSize);

    return () => {
      window.removeEventListener("resize", updateAppSize);
    };
  }, []);
  return (
    <div className="w-full bg-custom-gradient min-h-screen fixed inset-0">
      <div
        className="relative"
        style={{
          height: `${screenHeight * 0.7}px`,
          maxHeight: `${screenHeight * 0.7}px`,
        }}
      >
        <img src={SharkBg} alt="" className="w-full h-full object-cover" />
        <div className="absolute bg-gradient-to-b from-[#000000]/0 to-[#001539] bottom-0 right-0 left-0 z-10 h-32"></div>
        <div className="absolute bg-gradient-to-b from-[#000000]/0 to-[#001539] bottom-0 right-0 left-0 z-10 h-32">
          <h1 className="text-center text-white pt-20 text-4xl font-obliqueBold tracking-wide z-50">
            MEG
          </h1>
        </div>
      </div>
      <div className="bg-gradient-to-b from-[#001539] to-[#001539] h-full">
        <div className="flex flex-col justify-center items-center gap-10">
          <h1
            className="font-obliqueBold  text-[#FFC94A] text-3xl"
            style={{ textShadow: "0 2px 10px #FFC94A" }}
          >
            Will Be Launched Soon
          </h1>
          <p className="text-[#B3B3B3] text-2xl font-aileronItalic">
            STAY TUNED
          </p>
          <div className="flex justify-center items-center ">
            <LoadingIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingSplash;
